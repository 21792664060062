/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import { getOnSiteMarketingCustomLocation } from "../helpers/DrupalHelper"
import StateContext from "../context/state/StateContext"
import { parse } from "query-string"
import BannerSlider from "../components/Commons/BannerSlider"
import LayoutPage from "../components/Layout/Pages"
import PageSubtitle from "../components/PageSubtitle"
import ProductGridCard from "../components/Cards/ProductGridCard"
import ProductListCard from "../components/Cards/ProductListCard"
import { classNames } from "../utils"
import Pagination from "../components/Pagination"
import Container from "../components/Container"
import { getAssetListingGroups, getTagCategory } from "../helpers/DrupalHelper"
import DataContext from "../context/Data/DataContext"

import AssetsSideBarFilter from "../components/Elements/AssetsSideBarFilter"
import AssetsFilter from "../components/Elements/AssetsFilter"
import Listing from "../components/Elements/Listing"
import { useLocation } from "@reach/router"
import { useRef } from "react"
import mapSingle from "../utils/mapSingle"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import AccessDenied from "../components/AccessDenied"
import Separator from "../components/Elements/Separator"
import Skeleton from "../components/Skeleton"
import FilterBar from "../components/Elements/Listing/FilterBar"
import { navigate } from "gatsby"
const sortOptions = [
  { label: "Title (ascending)", value: { title: "asc" } },
  { label: "Title (descending)", value: { title: "desc" } },
  { label: "Oldest", value: { created: "asc" } },
  { label: "Newest ", value: { created: "desc" } },
  { label: "Recently Updated", value: { changed: "desc" } },
  // { label: "Most Content ", value: { files: "desc" } },
]
const viewOptions = [
  { label: "View 12", value: 15 },
  { label: "View 24", value: 30 },
  { label: "View 48", value: 45 },
]

export default function AssetListingGroups(props) {
  const { state } = useContext(StateContext)
  const { authentication, userType } = useContext(AuthenticationContext)
  const [loading, setLoading] = useState(true)

  const { token } = state
  const [serverData, setServerData] = useState(null)

  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const tagFromURL = urlSearchParams.get("tag")

  const { entityItemsNumber } = useContext(DataContext).data
  const [hero, setHero] = useState([])
  const [adCode, setAdCode] = useState("")

  const [pageNumber, setPageNumber] = useState(1)
  const [allItems, setAllItems] = useState([])
  const [pageItemsPN, setPageItemsPN] = useState({
    prevPageItems: [],
    nextPageItems: [],
    currentItems: allItems,
  })

  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [complete, setComplete] = useState(false)
  const [perPage, setPerPage] = useState(15)

  // const [isDesktop, setIsDesktop] = useState(
  //   window.matchMedia("(min-width: 992px)").matches
  // )

  // useEffect(() => {
  //   const handleResize = () =>
  //     setIsDesktop(window.matchMedia("(min-width: 992px)").matches)
  //   window.addEventListener("resize", handleResize)
  //   return () => window.removeEventListener("resize", handleResize)
  // }, [])

  useEffect(() => {
    if (complete) {
      window.listStateAsset = {
        serverData,
        pageNumber,
        allItems,
        totalPage,
        total,
        perPage,
        filterBody,
        scrollPosition: window.pageYOffset,
        filterOpen,
        showSideBar,
      }
    }
  })

  useEffect(async () => {
    if (window.PressedBack && window.listStateAsset) {
      // dispatch()
      setLoading(true)

      let {
        serverData,
        pageNumber,
        allItems,
        totalPage,
        total,
        perPage,
        filterBody,
        filterOpen,
        showSideBar,
        scrollPosition,
      } = window.listStateAsset
      await mapSingle(Object.keys(window.listStateAsset), v => {
        if (v == "allItems") setAllItems(allItems)
        else if (v == "serverData") setServerData(serverData)
        else if (v == "pageNumber") setPageNumber(pageNumber)
        else if (v == "totalPage") setTotalPage(totalPage)
        else if (v == "total") setTotal(total)
        else if (v == "perPage") setPerPage(perPage)
        else if (v == "filterBody") setFilterBody(filterBody)
        else if (v == "filterOpen") setFilterOpen(filterOpen)
        else if (v == "showSideBar") setShowSideBar(showSideBar)
      })

      window.removeEventListener("popstate", () => {
        // console.log("event removed")
      })
      window.PressedBack = false
      setLoading(false)
      setComplete(true)
      // window.scrollTo({
      //   top: scrollPosition,
      //   behavior: "smooth",
      // })
    } else {
      setComplete(true)

      if (location.search) {
        let { p } = parse(location.search)
        if (p) onPageChange(parseInt(p))
      } else {
        try {
          const res = await getAssetListingGroups(token, {
            // page: 1,
            offset: "0",
            item_per_page: "15",
            categories: [],
            type: ["digital", "physical"],
            sort: [{ created: "desc" }],
            tags: "",
            in_stock_only: true,
          })

          setServerData(res)
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!userType.brandManager && !userType.brandEditor) {
      getOnSiteMarketingCustomLocation(token, "20").then(res => {
        res.items?.length && setHero(res.items[0]["items"])
      })
      getOnSiteMarketingCustomLocation(token, "7,8,9,10").then(res => {

        // console.log(res, 'YAOOO')

        res.items?.length && setAdCode(res.items)
      })
    }
  }, [])
  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    if (pages > 1) setTotalPage(pages)
  }, [serverData])

  // useEffect(() => {
  //   setTotal(entityItemsNumber.assets_and_groups)
  // }, [entityItemsNumber])
  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    if (pages > 1) setTotalPage(pages)
  }, [perPage])

  const Card = ProductGridCard
  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    setTotalPage(pages)
  }, [total])

  const { tagsAsOptions: tags } = useContext(DataContext).data
  const [categories, setCategories] = useState([])
  useEffect(async () => {
    let catData = await getTagCategory(state.token)
    let cData = catData.map(v => {
      return { value: v.id, label: v.title }
    })
    setCategories([...cData])
  }, [])

  let firstBody = {
    item_per_page: "15",
    offset: "0",
    categories: [],
    type: ["digital", "physical"],
    sort: [{ created: "desc" }],
    tags: [],
    title: "",
    brand: [],
    in_stock_only: true,
  }
  const [filterBody, setFilterBody] = useState(firstBody)
  const onPageChange = async v => {
    setLoading(true)
    setPageNumber(v)

    let reqBody = {
      ...filterBody,
      offset: ((parseInt(v) - 1) * perPage).toString(),
    }
    setFilterBody(reqBody)
    let newPageData = await getAssetListingGroups(state.token, reqBody)

    setAllItems([...newPageData.results])
    setTotal(newPageData.total)
    setLoading(false)
  }

  useEffect(() => {
    if (
      JSON.stringify(filterBody) === JSON.stringify(firstBody) &&
      serverData !== null
    ) {
      setAllItems([...serverData.results])
      setTotal(serverData.total)
    }
  }, [filterBody])

  useEffect(() => {
    if (serverData !== null) {
      setAllItems([...serverData.results])
      setTotal(serverData.total)
    }
  }, [serverData])
  const onFilterBodyChange = async v => {
    setLoading(true)
    setPageNumber(1)
    let filteredAssets = await getAssetListingGroups(state.token, {
      ...v,
      offset: "0",
    })
    setAllItems([...filteredAssets.results])
    setFilterBody(v)
    setTotal(filteredAssets.total)
    setLoading(false)
  }
  useEffect(async () => {
    let { state } = location
    if (state?.category && state?.tags) {
      let body = {
        type: "",
        categories: state.category?.value ? [state.category?.value] : [],
        offset: "0",
        sort: [],
        tags: state.tags ? [state.tags.value] : [],
        item_per_page: "15",
        in_stock_only: true,
      }
      let filteredAssets = await getAssetListingGroups(token, body)

      setAllItems([...filteredAssets.results])
      setTotal(filteredAssets.total)
    }
  }, [])
  const callReset = async () => {
    setLoading(true)
    setPageNumber(1)
    setFilterBody(firstBody)
    const res = await getAssetListingGroups(token, {
      offset: "0",
      item_per_page: "15",
      categories: [],
      brand: [],
      type: ["digital", "physical"],
      sort: [{ created: "desc" }],
      tags: [],
      title: "",
      in_stock_only: true,
    })

    setAllItems([...res.results])
    setLoading(false)
    console.log(res)
  }

  const [gridView, setGridView] = useState(true)
  const toggleGridView = () => setGridView(true)
  const toggleListView = () => setGridView(false)

  const [filterOpen, setFilterOpen] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const scrollRef = useRef()
  if (!authentication.currentUserData.companies.length) {
    navigate("/company-status")
  }

  return (
    <div className="bg-white">
      <LayoutPage metaTitle={"Assets"}>
        <div className="pt-[20px]">
          {!userType.brandEditor && <BannerSlider items={hero} />}
          <div>
            {/* Mobile filter dialog */}

            <Container>
              <section
                aria-labelledby="products-heading"
                className="pt-1 pb-24"
              >
                <PageSubtitle
                  title="Marketing assets to help you sell products from your store"
                  marginTop={
                    userType.brandManager
                      ? "20px"
                      : userType.brandEditor && "60px"
                  }
                />
                {
                  <div className="w-full pr-5">
                    <AssetsFilter
                      open={filterOpen}
                      setOpen={setFilterOpen}
                      setShowSideBar={setShowSideBar}
                      setFilterBody={setFilterBody}
                      onFilterBodyChange={onFilterBodyChange}
                      fromPage={true}
                      filterBody={filterBody}
                      showSideBar={showSideBar}
                      toggleSidebar={() => {
                        setShowSideBar(true)
                        setFilterOpen(false)
                      }}
                      // className={classNames(
                      //   "min-w-[20%] hidden ",
                      //   !showSideBar ? "" : "lg:block"
                      // )}
                      callReset={callReset}
                      tagFromURL={tagFromURL}
                      loading={loading}
                    />
                  </div>
                }
                <div class="flex justify-between max-lg:hidden">
                  <p className="flex text-reg-15 text-dark">
                    Showing{" "}
                    {loading ? <Skeleton fromText /> : filterBody.item_per_page}{" "}
                    of {total} results
                  </p>
                  <div class="flex">
                    <p class="text-reg-15 text-dark px-5">Sort by:</p>
                    <FilterBar
                      // toggleGridView={toggleGridView}
                      // toggleListView={toggleListView}
                      dataLength={
                        loading
                          ? Array(20)
                              .fill(1)
                              .map(v => ({
                                id: "",
                                title: "",
                                bundle: "default",
                                description: "",
                                cover: [""],
                                tags: [],
                                category: [],
                                type: "digital",
                                brand: {
                                  name: "",
                                  logo: "",
                                },
                              })).length
                          : allItems.length
                      }
                      viewOptions={viewOptions}
                      // forceTray={forceTray}
                      metaHideReset={true}
                      values={filterBody}
                      viewOptionsCallback={v => {
                        onFilterBodyChange({
                          ...filterBody,
                          item_per_page: v.toString(),
                        })
                        setPerPage(v)
                      }}
                      sortOptions={sortOptions}
                      sortOptionsCallback={v => {
                        onFilterBodyChange({
                          ...filterBody,
                          sort: [
                            {
                              ...v,
                            },
                          ],
                        })
                      }}
                      filterCallback={() => setFilterOpen(true)}
                      showSideBar={showSideBar}
                      loading={loading}
                      callReset={callReset}
                      total={
                        total
                          ? total
                          : props.assets
                          ? props.assetsGroup
                            ? entityItemsNumber.assets_and_groups
                            : entityItemsNumber.assets
                          : entityItemsNumber.products
                      }
                    />
                  </div>
                </div>
                <Separator className="mb-[30px]" />
                <div
                  className={classNames(
                    "flex",
                    showSideBar ? "justify-between" : "w-full"
                  )}
                >
                  {/* Filters */}

                  <div
                    className={classNames(
                      showSideBar ? "min-w-[80%] pl-[60px]" : "w-full"
                    )}
                  >
                    <Listing
                      listRef={scrollRef}
                      large={showSideBar ? false : true}
                      pageNumber={pageNumber}
                      fromAssetListing={true}
                      data={
                        loading
                          ? Array(20)
                              .fill(1)
                              .map(v => ({
                                id: "",
                                title: "",
                                bundle: "default",
                                description: "",
                                cover: [""],
                                tags: [],
                                category: [],
                                type: "digital",
                                brand: {
                                  name: "",
                                  logo: "",
                                },
                              }))
                          : allItems
                      }
                      showSideBar={showSideBar}
                      viewOptions={viewOptions}
                      callReset={callReset}
                      // gridView={gridView}
                      // setGridView={() => setGridView(!gridView)}
                      viewOptionsCallback={v => {
                        onFilterBodyChange({
                          ...filterBody,
                          item_per_page: v.toString(),
                        })
                        setPerPage(v)
                      }}
                      sortOptions={sortOptions}
                      sortOptionsCallback={v => {
                        onFilterBodyChange({
                          ...filterBody,
                          sort: [
                            {
                              ...v,
                            },
                          ],
                        })
                      }}
                      openFilters={() => setFilterOpen(true)}
                      // component={gridView === true ? Card : ProductListCard}
                      component={Card}
                      componentList={ProductListCard}
                      componentProps={{
                        hiddenCta: false,
                      }}
                      // assets={true}
                      // assetsGroup={true}
                      total={total}
                      adCode={adCode}
                      loading={loading}
                      filterBody={filterBody}
                      metaHideReset={true}
                    />

                    <Pagination
                      page={pageNumber}
                      setPage={v => onPageChange(v)}
                      totalPages={totalPage}
                      scrollRef={scrollRef}
                    />
                  </div>
                  <AssetsSideBarFilter
                    open={filterOpen}
                    setOpen={setFilterOpen}
                    setFilterBody={setFilterBody}
                    onFilterBodyChange={onFilterBodyChange}
                    fromPage={true}
                    toggleSidebar={() => {
                      setShowSideBar(true)
                      setFilterOpen(false)
                    }}
                    filterBody={filterBody}
                    callReset={callReset}
                  />
                </div>
              </section>
            </Container>
          </div>
        </div>
      </LayoutPage>
    </div>
  )
}
